import Link from 'next/link'
import Image from "next/image";
import dynamic from 'next/dynamic';

// import Plus from '../../assets/plus.svg'
const Plus = dynamic(() => import('../../assets/plus.svg'))

export interface GridItem {
  title: string;
  link: string;
  image?: string;
}

const Item = (item: GridItem) => (
  <div className='relative group border-b border-white'>
    {item.image && (
      <div className='relative h-48 lg:h-64 w-full'>
        <Image
          alt={item.title}
          src={item.image}
          className='object-cover h-48 lg:h-64 w-full'
          placeholder='blur'
          blurDataURL='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAwIiBoZWlnaHQ9IjQ3NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4='
          fill
          sizes="100vw" />
      </div>
    )}
    {!item.image && (<div className='object-cover bg-gold-600 h-24 lg:h-24 w-full' />)}
    <div className='absolute inset-0 flex flex-col justify-end p-4 md:p-8 text-white z-10'>
      <Link
        href={item.link}
        passHref
        className='text-white text-xl uppercase flex justify-between'>

        <span>{item.title}</span>
        {/* @ts-ignore */}
        <Plus className='self-end' />

      </Link>
    </div>
    {item.image && (<div className='absolute inset-0 bg-black opacity-40 z-0 duration-500 transition-opacity group-hover:opacity-20' />)}
    <a
      href={item.link}
      className="stretch-link"
    ><span className='sr-only'>Learn more about {item.title} - bespoke treatment solutions designed for each individual client at Charmelle London</span></a>
  </div>
)

interface Props {
  columns?: number;
  mdColumns?: number;
  items: GridItem[]
}

const calcColumns = (mdColumns: number | undefined, columns: number) => {
  if (mdColumns) return mdColumns;
  if (columns === 3) return columns
  return 2
}

export default function Grid(props: Props) {
  return (
    <div className='my-24'>
      <section className={`mt-8 md:mt-0 lg:my-8 grid grid-cols-1 sm:grid-cols-${calcColumns(props.mdColumns, props.columns || 4)} lg:grid-cols-${props.columns || 4} gap-0 rounded-md overflow-hidden shadow-md`}>
        {props.items.map((item, index) => (
          <Item key={`set-1-${index}`} {...item} />
        ))}
      </section>
    </div>
  )
}
